<template>
    <zw-sidebar @shown="shown" :title="title">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-card :header="$t('article.group.basic')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3" v-if="type == 'food'">
                                <custom-field v-model="form.customValues['food_type']"
                                              :field="form.customFields['food_type']"
                                              @input="(val)=>{form.name=val}"
                                ></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <zw-input-group v-model="form.name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.sku"
                                                name="sku"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group :label="$t(labelPrefix+'categories')">
                                    <multiselect
                                        v-model="form.category_ids"
                                        :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                                        :multiple="true"
                                        :custom-label="opt => getCategoryOptions()[opt]"
                                        :readonly="type ? true : false"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.type"
                                                 :options="getTypes() | optionsVV"
                                                 name="type"
                                                 :label-prefix="labelPrefix"
                                                 :readonly="type ? true : false"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3" v-if="type == 'transfer'">
                                <zw-select-group v-model="form.city"
                                                 :options="getHotelsCities() | optionsVV"
                                                 name="city"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="3" v-if="type == 'food' || type == 'service'">
                                <zw-select-group v-model="form.customValues['hotel']"
                                                 :name="'custom.'+form.customFields['hotel'].model+'.'+form.customFields['hotel'].name"
                                                 :label="form.customFields['hotel'].options.label"
                                                 :options="getHotelsList()"
                                                 valueField="id"
                                                 textField="name"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>
                            <b-col cols="3" v-if="type == 'service'">
                                <custom-field v-model="form.customValues['service_type']"
                                              :field="form.customFields['service_type']"
                                ></custom-field>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <zw-ckeditor v-model="form.description"></zw-ckeditor>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.price')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <zw-input-group v-model="form.purchase_price"
                                                name="purchase_price"
                                                :label-prefix="labelPrefix"
                                                @input="purchasePriceChanged"
                                                type="number"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="3">
                                <zw-input-group v-model="form.sales_price"
                                                name="sales_price"
                                                :label-prefix="labelPrefix"
                                                type="number"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="3" v-if="type">
                                <zw-select-group v-model="form.vat"
                                                 :options="getVats() | optionsVL"
                                                 name="vat"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="12" v-if="type == 'insurance'">
                                <custom-field v-model="form.customValues['insurance_prices']"
                                              :field="form.customFields['insurance_prices']"
                                ></custom-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card v-if="type == 'food'" :header="$t('article.group.childs')">
                    <b-card-text>
                        <b-row v-if="form.customValues['children_discounts'].length">
                            <b-col cols="1">{{ $t('article.label.age_discounts.age_from') }}</b-col>
                            <b-col cols="1">{{ $t('article.label.age_discounts.age_to') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.age_discounts.type') }}</b-col>
                            <b-col cols="1">{{ $t('article.label.age_discounts.value') }}</b-col>
                        </b-row>

                        <child-discount-line v-for="(row,index) in form.customValues['children_discounts']"
                                             :key="index"
                                             v-model="form.customValues['children_discounts'][index]"
                                             :remove="()=>{removeDiscountLine(index)}"
                        ></child-discount-line>

                        <b-row>
                            <b-col cols="1">
                                <b-button variant="primary" @click="addDiscountLine">
                                    <font-awesome-icon icon="plus"/>
                                </b-button>
                            </b-col>
                        </b-row>


                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="3" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ChildDiscountLine from "../booking/components/ChildDiscountLine";

export default {
    components: {ChildDiscountLine},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                type: 'simple',
                quantity: 1,
            },
            form: {},
            type: null,
            title: null,
            labelPrefix: 'article.label.',
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getTypes']),
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('CommonData', ['getSettings', 'getUnits', 'getVats']),
        ...mapGetters('Category', ['getCategoryOptions']),
        ...mapGetters('Hotels', ['getHotelsCities', 'getHotelsList']),
        shown() {
            this.title = this.$t('article.title.service')

            const articles = this.$store.dispatch('Articles/fetchArticle', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions')
            const articleTypes = this.$store.dispatch('Articles/fetchTypes')
            const hotelsCities = this.$store.dispatch('Hotels/fetchHotelsCities')
            const hotelsList = this.$store.dispatch('Hotels/fetchHotelsList')

            // todo: don't fetch hotels data if modules disabled
            Promise.all([articles, categories, articleTypes, hotelsCities, hotelsList])
                .then((values) => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getArticle()))
                    }
                    if(!this.form.category_ids.length) {
                        this.form.category_ids = [this.getSettings()['booking_service_category']]
                    }

                    this.form.customValues['children_discounts'] = JSON.parse(this.form.customValues['children_discounts']) || []

                    if (this.payload.type == 'transfer') {
                        this.type = 'transfer'
                        this.title = this.$t('article.title.transfer')
                        if (!this.form.id) {
                            this.form.sku = this.getSettings()['transfer_sku_prefix']
                            this.form.category_ids = [this.getSettings()['transfer_category']]
                        }
                    }
                    if (this.payload.type == 'insurance') {
                        this.type = 'insurance'
                        this.title = this.$t('article.title.insurance')
                        if (!this.form.id) {
                            this.form.sku = this.getSettings()['insurance_sku_prefix']
                            this.form.category_ids = [this.getSettings()['insurance_category']]
                        }
                    }
                    if (this.payload.type == 'food') {
                        this.title = this.$t('article.title.food')
                        this.type = 'food'
                        if (!this.form.id) {
                            this.form.sku = this.getSettings()['food_sku_prefix']
                            this.form.category_ids = [this.getSettings()['food_category']]
                        }
                    }
                    if (this.payload.type == 'service') {
                        this.type = 'service'
                        if (!this.form.id) {
                            this.form.sku = this.getSettings()['service_sku_prefix']
                        }
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Articles/saveArticle', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        purchasePriceChanged(val) {
            if (['food', 'service', 'transfer'].includes(this.type)) {
                this.form.sales_price = (val * 1.25).toFixed(2)
            }
        },
        addDiscountLine() {
            this.form.customValues['children_discounts'].push({})
        },
        removeDiscountLine(index) {
            this.form.customValues['children_discounts'] = this.form.customValues['children_discounts'].filter((data, i) => {
                return i != index
            })
        },
    }
}
</script>